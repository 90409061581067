import axios from './axios.js'

export const getClientsRequest = () => axios.get('/users')

export const getClientRequest = (username) => axios.get(`/users/${username}`)

export const createClientRequest = (client) => axios.post('/users', client)

export const deleteClientRequest = (username) => axios.delete(`/users/${username}`)

export const updateUsernameClientRequest = (username, newUsername) => axios.put(`/users/username/${username}`, newUsername)

export const updatePasswordClientRequest = (username, password) => axios.put(`/users/password/${username}`, password)

export const updatePhoneClientRequest = (username, phone) => axios.put(`/users/phone/${username}`, phone)

export const updateActiveClientRequest = (username) => axios.put(`/users/active/${username}`)

export const updateExpirationClientRequest = (username, expiration) => axios.put(`/users/expiration/${username}`, expiration)

export const updateExpirationMonthClientRequest = (username, month) => axios.put(`/users/expiration-month/${username}`, month)