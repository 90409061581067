import './css/HomePage.css'
import { useEffect, useState } from "react"
import {useForm} from 'react-hook-form'
import { useClients } from "../context/ClientContext.js"
import Client from "../components/Client.js"
import Navbar from "../components/Navbar.js"
import { Spinner } from 'reactstrap';
import Modal from '../components/Modal.js'

const HomePage = () => {
    const {getClients, createClient, clients, loading, createErrors, setCreateErrors, loadingCreateClient} = useClients()
    const {register, handleSubmit, formState: {errors}, reset} = useForm()
    const [filtroActivo, setFiltroActivo] = useState("username")
    const [valueFiltro, setValueFiltro] = useState("")
    const [listClients, setListClients] = useState([])
    const [showAdd, setShowAdd] = useState(false)

    useEffect(() => {
        getClients()
    }, [])

    useEffect(() => {
        setListClients(clients)
    }, [clients])

    const changeFiltro = (e) => {
        setFiltroActivo(e.target.value)
        setListClients(clients)
        setValueFiltro("")
    }

    const changeBuscar = (e) => {
        const busqueda = e.target.value.toLowerCase()
        setValueFiltro(e.target.value)
        if(e.target.value === '') {
            setListClients(clients)
        } else if(filtroActivo === 'username') {
            setListClients(clients.filter(client => client.username.toLowerCase().includes(busqueda)))
        } else {
            setListClients(clients.filter(client => client.phone.toLowerCase().includes(busqueda)))
        }
    }

    const abrirModalAgregar = () => setShowAdd(true)

    const cerrarModalAgregar = () => {
        setShowAdd(false)
        reset()
        setCreateErrors([])
    }

    const agregarCliente = handleSubmit(async (values) => {
        if(await createClient(values)) {
            cerrarModalAgregar()
        }
    })

    return (
        <div className="home-page">
            <Navbar />
            <div className='clients-page'>
                <div className="container">
                    <div className="row">
                        <div className='box box-filtros'>
                            <div className='filtros col-12 col-md-5'>
                                <label>Filtros</label>
                                <select defaultValue="username" className='col' onChange={changeFiltro}>
                                    <option value="username">Nombre de usuario</option>
                                    <option value="phone">Teléfono</option>
                                </select>
                            </div>
                            <div className='buscar col-12 col-md-5'>
                                <label>Buscar</label>
                                <input onChange={changeBuscar} value={valueFiltro} className='col' type='text' placeholder='Buscar...'></input>
                            </div>
                            <div className='crear col-12 col-md-2'>
                                <button onClick={abrirModalAgregar}>Crear usuario</button>
                            </div>
                        </div>
                        <div className='box box-clients'>
                            <div className='box-clients-title'>Usuarios</div>
                            <div className='box-clients-table'>
                                <div className='row'>
                                    <div className='col-3 box-clients-table-title'>Usuario</div>
                                    <div className='col-2 box-clients-table-title'>Teléfono</div>
                                    <div className='col-3 box-clients-table-title'>Vencimiento</div>
                                    <div className='col-2 box-clients-table-title'>Activo</div>
                                    <div className='col-2 box-clients-table-title'>Acciones</div>
                                </div>
                                <div className='row'>
                                    {loading
                                    ? <div className='col-12 box-clients-table-users'><Spinner /></div>
                                    : listClients.length === 0
                                        ? <div className='col-12 box-clients-table-users'>No hay usuarios</div>
                                        : listClients.map((client, index) => (
                                            <Client client={client} key={client.username} index={index} />
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={showAdd} setShow={cerrarModalAgregar}>
                <div className='client-modal-children'>
                    <div className='client-modal-title fw-bold'>Nuevo usuario</div>
                    <form className='client-modal-inputs' onSubmit={agregarCliente}>
                        <div className='col-12 client-modal-inputs-row'>
                            <label className='col-3 text-center'>Usuario</label>
                            <input 
                                className='col-9' type='text' placeholder='Nombre de usuario...'
                                {...register('username', {required: true})} 
                            />
                        </div>
                        {errors.username && <p className='client-modal-inputs-errors'>El nombre de usuario es requerido</p>}
                        <div className='col-12 client-modal-inputs-row'>
                            <label className='col-3 text-center'>Password</label>
                            <input 
                                className='col-9' type='text' placeholder='Password...'
                                {...register('password', {required: true, minLength: 6})}
                            />
                        </div>
                        {errors.password && errors.password.type === 'required' && <p className='client-modal-inputs-errors'>La contraseña es requerida</p>}
                        {errors.password && errors.password.type === 'minLength' && <p className='client-modal-inputs-errors'>La contraseña debe tener al menos 6 carácteres</p>}
                        <div className='col-12 client-modal-inputs-row'>
                            <label className='col-3 text-center'>Teléfono</label>
                            <input
                                className='col-9' type='number' placeholder='Teléfono...'
                                {...register('phone', {required: true, min: 0})} 
                            />
                        </div>
                        {errors.phone && errors.phone.type === 'required' && <p className='client-modal-inputs-errors'>El teléfono es requerido</p>}
                        {errors.phone && errors.phone.type === 'min' && <p className='client-modal-inputs-errors'>El teléfono no puede ser negativo</p>}
                        {createErrors.length > 0 && <div className='login-page-errors'>
                            {createErrors.map((error, i) => (
                                <div key={i}>{error.message}</div>
                            ))}
                            </div>
                        }
                        {createErrors.message && <div className='login-page-errors'>
                            <div>{createErrors.message}</div>
                        </div>
                        }
                        {loadingCreateClient && <div className='client-modal-inputs-row'>
                                <Spinner />
                            </div>
                        }
                        <div className='client-modal-buttons'>
                            <button type='submit' onClick={agregarCliente} className='client-modal-buttons-blue'>Agregar</button>
                            <button type='reset' onClick={cerrarModalAgregar} className='client-modal-buttons-red'>Cancelar</button>
                        </div>
                    </form>
                </div>
            </Modal>
        </div>
    )
}

export default HomePage