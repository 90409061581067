import './css/Client.css'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Spinner } from 'reactstrap';
import { useClients } from '../context/ClientContext.js'
import Modal from './Modal.js'
import { format } from 'date-fns'
import { MdEdit, MdDelete } from "react-icons/md";

function Client({client, index}) {
    const {deleteClient, deleteErrors, setDeleteErrors, loadingDeleteClient, updateErrors, setUpdateErrors, loadingUpdateClient, updateUsernameClient, updatePasswordClient, updateActiveClient, updatePhoneClient, updateExpirationClient, updateExpirationMonthClient} = useClients()
    const {register, handleSubmit, formState: {errors}, reset} = useForm()
    const {register: register1, handleSubmit: handleSubmit1, formState: {errors: errors1}, reset: reset1} = useForm()
    const {register: register2, handleSubmit: handleSubmit2, formState: {errors: errors2}, reset: reset2} = useForm()
    const {register: register3, handleSubmit: handleSubmit3, formState: {errors: errors3}, reset: reset3} = useForm()
    const {register: register4, handleSubmit: handleSubmit4, formState: {errors: errors4}, reset: reset4} = useForm()
    const {register: register5, handleSubmit: handleSubmit5, formState: {errors: errors5}, reset: reset5} = useForm()
    const {register: register6, handleSubmit: handleSubmit6, formState: {errors: errors6}, reset: reset6} = useForm()
    const [showEdit, setShowEdit] = useState(false)
    const [showDelete, setShowDelete] = useState(false)

    const [showEditUsername, setShowEditUsername] = useState(false)
    const [disabledButtonEditUsername, setDisabledButtonEditUsername] = useState(true)
    const [valueEditUsername, setValueEditUsername] = useState(client.username)

    const [showEditPassword, setShowEditPassword] = useState(false)

    const [showEditActive, setShowEditActive] = useState(false)

    const [showEditExpiration, setShowEditExpiration] = useState(false)

    const [showEditExpirationMonth, setShowEditExpirationMonth] = useState(false)

    const [showEditPhone, setShowEditPhone] = useState(false)
    const [disabledButtonEditPhone, setDisabledButtonEditPhone] = useState(true)
    const [valueEditPhone, setValueEditPhone] = useState(client.phone)

    // ------------------------------------------------------------------------------------------------

    const abrirModalEditar = () => setShowEdit(true)

    const abrirModalBorrar = () => setShowDelete(true)

    const cerrarModalEditar = () => setShowEdit(false)

    const cerrarModalBorrar = () => {
        setShowDelete(false)
        setDeleteErrors([])
    }

    const borrarCliente = async () => {
        if(await deleteClient(client.username)) {
            cerrarModalBorrar()
        }
    }

    // USERNAME----------------------------------------------------------------------------------------

    const resetForm4 = () => {
        reset4()
        setUpdateErrors([])
    }

    const abrirModalEditarUsername = () => {
        cerrarModalEditar()
        setValueEditUsername(client.username)
        setShowEditUsername(true)
    }

    const cerrarModalEditarUsername = () => {
        setShowEditUsername(false)
        resetForm4()
        setDisabledButtonEditUsername(true)
    }

    const editarUsernameCliente = handleSubmit4(async (values) => {
        values.username = valueEditUsername
        if(await updateUsernameClient(client.username, values)) {
            cerrarModalEditarUsername()
        }
    })

    const verificarBotonEditUsername = (e) => {
        if(e.target.value === client.username) {
            setDisabledButtonEditUsername(true)
        } else {
            setDisabledButtonEditUsername(false)
        }
        setValueEditUsername(e.target.value)
    }

    // PASSWORD----------------------------------------------------------------------------------------

    const resetForm1 = () => {
        reset1()
        setUpdateErrors([])
    }

    const abrirModalEditarPassword = () => {
        cerrarModalEditar()
        setShowEditPassword(true)
    }

    const cerrarModalEditarPassword = () => {
        setShowEditPassword(false)
        resetForm1()
    }

    const editarPasswordCliente = handleSubmit1(async (values) => {
        if(await updatePasswordClient(client.username, values)) {
            cerrarModalEditarPassword()
        }
    })

    // ACTIVE------------------------------------------------------------------------------------------

    const abrirModalEditarActive = () => {
        cerrarModalEditar()
        setShowEditActive(true)
    }

    const cerrarModalEditarActive = () => {
        setShowEditActive(false)
        setUpdateErrors([])
    }

    const editarActiveCliente = async () => {
        if(await updateActiveClient(client.username)) {
            cerrarModalEditarActive()
        }
    }

    // EXPIRATION--------------------------------------------------------------------------------------

    const resetForm5 = () => {
        reset5()
        setUpdateErrors([])
    }

    const abrirModalEditarExpiration = () => {
        cerrarModalEditar()
        setShowEditExpiration(true)
    }

    const cerrarModalEditarExpiration = () => {
        setShowEditExpiration(false)
        resetForm5()
    }

    const borrarExpirationCliente = handleSubmit5(async (values) => {
        values = {expiration: 0}
        if(await updateExpirationClient(client.username, values)) {
            cerrarModalEditarExpiration()
        }
    })

    const editarExpirationCliente = handleSubmit5(async (values) => {
        values.expiration = Number(values.expiration)
        if(await updateExpirationClient(client.username, values)) {
            cerrarModalEditarExpiration()
        }
    })

    // EXPIRATION-MONTH------------------------------------------------------------------------------------------

    const resetForm6 = () => {
        reset6()
        setUpdateErrors([])
    }

    const abrirModalEditarExpirationMonth = () => {
        cerrarModalEditar()
        setShowEditExpirationMonth(true)
    }

    const cerrarModalEditarExpirationMonth = () => {
        setShowEditExpirationMonth(false)
        resetForm6()
    }

    const editarExpirationMonthCliente = handleSubmit6(async (values) => {
        values.months = Number(values.months)
        if(await updateExpirationMonthClient(client.username, values)) {
            cerrarModalEditarExpirationMonth()
        }
    })

    // PHONE----------------------------------------------------------------------------------------

    const resetForm = () => {
        reset()
        setUpdateErrors([])
    }

    const abrirModalEditarPhone = () => {
        cerrarModalEditar()
        setValueEditPhone(client.phone)
        setShowEditPhone(true)
    }

    const cerrarModalEditarPhone = () => {
        setShowEditPhone(false)
        resetForm()
        setDisabledButtonEditPhone(true)
    }

    const editarPhoneCliente = handleSubmit(async (values) => {
        values.phone = valueEditPhone
        if(await updatePhoneClient(client.username, values)) {
            cerrarModalEditarPhone()
        }
    })

    const verificarBotonEditPhone = (e) => {
        if(e.target.value === client.phone) {
            setDisabledButtonEditPhone(true)
        } else {
            setDisabledButtonEditPhone(false)
        }
        setValueEditPhone(e.target.value)
    }

    return (
        <div className={'col-12 ' + (index % 2 == 0 ? 'box-clients-table-users' : 'box-clients-table-users-gray')}>
            <div className='col-3 text-center text-break ps-1 pe-1 fw-bold'>{client.username}</div>
            <div className='col-2 text-center text-break ps-1 pe-1'>{client.phone}</div>
            <div className='col-3 text-center text-break ps-1 pe-1'>
                {client.expiration === null
                ? 'Sin vencimiento'
                : <span className={`badge ${new Date() < new Date(client.expiration) ? 'badge-success text-wrap' : 'badge-danger text-wrap'}`}>
                    {format(new Date(client.expiration), 'dd-MM-yyyy HH:mm') + 'hs'}
                </span>
                }
            </div>
            <div className='col-2 text-center ps-1 pe-1'>
                {client.active
                ? <span className='badge badge-success'>
                    Si
                </span>
                : <span className='badge badge-danger'>
                    No
                </span>
                }
            </div>
            <div className='col-2 text-center ps-1 pe-1 box-clients-table-users-buttons'>
                <MdEdit className='me-1' size='20px' onClick={abrirModalEditar} />
                <MdDelete size='20px' onClick={abrirModalBorrar} />
            </div>
            <Modal
                show={showEdit}
                setShow={setShowEdit}
            >
                <div className='client-modal-children'>
                    <div className='client-modal-title fw-bold'>Editar usuario <span>{client.username}</span></div>
                    <div className='col-12 client-modal-edit'>
                        <button className='col-12' onClick={abrirModalEditarUsername}>Editar nombre de usuario</button>
                        <button className='col-12' onClick={abrirModalEditarPassword}>Editar password</button>
                        <button className='col-12' onClick={abrirModalEditarPhone}>Editar teléfono</button>
                        <button className='col-12' onClick={abrirModalEditarActive}>Pasar de {client.active ? 'activo a inactivo' : 'inactivo a activo'}</button>
                        <button className='col-12' onClick={abrirModalEditarExpiration}>{client.expiration === null ? 'Crear expiración' : 'Editar expiración'}</button>
                        <button className='col-12' onClick={abrirModalEditarExpirationMonth}>Registrar pago</button>
                    </div>
                    <div className='client-modal-buttons'>
                        <button onClick={cerrarModalEditar} className='client-modal-buttons-red'>Cancelar</button>
                    </div>
                </div>
            </Modal>
            <Modal
                show={showDelete}
                setShow={cerrarModalBorrar}
            >
                <div className='client-modal-children'>
                    <div className='client-modal-title'>Se eliminará el usuario <span>{client.username}</span>, ¿estás seguro?</div>
                    {deleteErrors.length > 0 && <div className='login-page-errors'>
                        {deleteErrors.map((error, i) => (
                            <div key={i}>{error.message}</div>
                        ))}
                        </div>
                    }
                    {deleteErrors.message && <div className='login-page-errors'>
                        <div>{deleteErrors.message}</div>
                    </div>
                    }
                    {loadingDeleteClient && <div className='client-modal-inputs-row'>
                            <Spinner />
                        </div>
                    }
                    <div className='client-modal-buttons'>
                        <button onClick={borrarCliente} className='client-modal-buttons-red'>Eliminar</button>
                        <button onClick={cerrarModalBorrar} className='client-modal-buttons-blue'>Cancelar</button>
                    </div>
                </div>
            </Modal>
            <Modal
                show={showEditUsername}
                setShow={cerrarModalEditarUsername}
            >
                <div className='client-modal-children'>
                    <div className='client-modal-title fw-bold'>Editar usuario de <span>{client.username}</span></div>
                    <form className='client-modal-inputs' onSubmit={editarUsernameCliente}>
                        <div className='col-12 client-modal-inputs-row'>
                            <label className='col-3 text-center'>Usuario</label>
                            <input
                                className='col-9' type='text' placeholder='Nombre de usuario...'
                                {...register4('username', {required: true})}
                                onChange={verificarBotonEditUsername}
                                value={valueEditUsername}
                            />
                        </div>
                        {errors4.username && <p className='client-modal-inputs-errors'>El nombre de usuario es requerido</p>}
                        {updateErrors.length > 0 && <div className='login-page-errors'>
                            {updateErrors.map((error, i) => (
                                <div key={i}>{error.message}</div>
                            ))}
                            </div>
                        }
                        {updateErrors.message && <div className='login-page-errors'>
                            <div>{updateErrors.message}</div>
                        </div>
                        }
                        {loadingUpdateClient && <div className='client-modal-inputs-row'>
                                <Spinner />
                            </div>
                        }
                        <div className='client-modal-buttons'>
                            <button type='submit' onClick={editarUsernameCliente} className='client-modal-buttons-blue' disabled={disabledButtonEditUsername}>Guardar</button>
                            <button type='reset' onClick={cerrarModalEditarUsername} className='client-modal-buttons-red'>Cancelar</button>
                        </div>
                    </form>
                </div>
            </Modal>
            <Modal
                show={showEditPassword}
                setShow={cerrarModalEditarPassword}
            >
                <div className='client-modal-children'>
                    <div className='client-modal-title fw-bold'>Editar password de <span>{client.username}</span></div>
                    <form className='client-modal-inputs' onSubmit={editarPasswordCliente}>
                        <div className='col-12 client-modal-inputs-row'>
                            <label className='col-3 text-center'>Pass</label>
                            <input
                                className='col-9' type='text' placeholder='Nueva contraseña...'
                                {...register1('password', {required: true, minLength: 6})}
                            />
                        </div>
                        {errors1.password && errors1.password.type === 'required' && <p className='client-modal-inputs-errors'>La contraseña es requerida</p>}
                        {errors1.password && errors1.password.type === 'minLength' && <p className='client-modal-inputs-errors'>La contraseña debe tener al menos 6 carácteres</p>}
                        {updateErrors.length > 0 && <div className='login-page-errors'>
                            {updateErrors.map((error, i) => (
                                <div key={i}>{error.message}</div>
                            ))}
                            </div>
                        }
                        {updateErrors.message && <div className='login-page-errors'>
                            <div>{updateErrors.message}</div>
                        </div>
                        }
                        {loadingUpdateClient && <div className='client-modal-inputs-row'>
                                <Spinner />
                            </div>
                        }
                        <div className='client-modal-buttons'>
                            <button type='submit' onClick={editarPasswordCliente} className='client-modal-buttons-blue'>Guardar</button>
                            <button type='reset' onClick={cerrarModalEditarPassword} className='client-modal-buttons-red'>Cancelar</button>
                        </div>
                    </form>
                </div>
            </Modal>
            <Modal
                show={showEditPhone}
                setShow={cerrarModalEditarPhone}
            >
                <div className='client-modal-children'>
                    <div className='client-modal-title fw-bold'>Editar teléfono de <span>{client.username}</span></div>
                    <form className='client-modal-inputs' onSubmit={editarPhoneCliente}>
                        <div className='col-12 client-modal-inputs-row'>
                            <label className='col-3 text-center'>Teléfono</label>
                            <input
                                className='col-9' type='text' placeholder='Teléfono...'
                                {...register('phone', {required: true})}
                                onChange={verificarBotonEditPhone}
                                value={valueEditPhone}
                            />
                        </div>
                        {errors.phone && <p className='client-modal-inputs-errors'>El teléfono es requerido</p>}
                        {updateErrors.length > 0 && <div className='login-page-errors'>
                            {updateErrors.map((error, i) => (
                                <div key={i}>{error.message}</div>
                            ))}
                            </div>
                        }
                        {updateErrors.message && <div className='login-page-errors'>
                            <div>{updateErrors.message}</div>
                        </div>
                        }
                        {loadingUpdateClient && <div className='client-modal-inputs-row'>
                                <Spinner />
                            </div>
                        }
                        <div className='client-modal-buttons'>
                            <button type='submit' onClick={editarPhoneCliente} className='client-modal-buttons-blue' disabled={disabledButtonEditPhone}>Guardar</button>
                            <button type='reset' onClick={cerrarModalEditarPhone} className='client-modal-buttons-red'>Cancelar</button>
                        </div>
                    </form>
                </div>
            </Modal>
            <Modal
                show={showEditActive}
                setShow={cerrarModalEditarActive}
            >
                <div className='client-modal-children'>
                    <div className='client-modal-title'>Se pasará de {client.active ? 'activo a inactivo' : 'inactivo a activo'} al usuario <span>{client.username}</span>, ¿estás seguro?</div>
                    {updateErrors.length > 0 && <div className='login-page-errors'>
                        {updateErrors.map((error, i) => (
                            <div key={i}>{error.message}</div>
                        ))}
                        </div>
                    }
                    {updateErrors.message && <div className='login-page-errors'>
                        <div>{updateErrors.message}</div>
                    </div>
                    }
                    {loadingUpdateClient && <div className='client-modal-inputs-row'>
                            <Spinner />
                        </div>
                    }
                    <div className='client-modal-buttons'>
                        <button onClick={editarActiveCliente} className='client-modal-buttons-blue'>Guardar</button>
                        <button onClick={cerrarModalEditarActive} className='client-modal-buttons-red'>Cancelar</button>
                    </div>
                </div>
            </Modal>
            <Modal
                show={showEditExpiration}
                setShow={cerrarModalEditarExpiration}
            >
                {client.expiration !== null
                ? <div className='client-modal-children'>
                    <div className='client-modal-title fw-bold'>Editar expiración de <span>{client.username}</span></div>
                    <form className='client-modal-inputs' onSubmit={borrarExpirationCliente}>
                        <div className='col-12 client-modal-inputs-row'>
                            <label>Expiración:</label>
                            <div>{format(new Date(client.expiration), 'dd-MM-yyyy HH:mm') + 'hs'}</div>
                        </div>
                        {updateErrors.length > 0 && <div className='login-page-errors'>
                            {updateErrors.map((error, i) => (
                                <div key={i}>{error.message}</div>
                            ))}
                            </div>
                        }
                        {updateErrors.message && <div className='login-page-errors'>
                            <div>{updateErrors.message}</div>
                        </div>
                        }
                        {loadingUpdateClient && <div className='client-modal-inputs-row'>
                                <Spinner />
                            </div>
                        }
                        <div className='client-modal-buttons'>
                            <button type='submit' onClick={async () => {
                                const values = {expiration: 0}
                                if(await updateExpirationClient(client.username, values)) {
                                    cerrarModalEditarExpiration()
                                }
                            }} className='client-modal-buttons-red'>Borrar</button>
                            <button type='reset' onClick={cerrarModalEditarExpiration} className='client-modal-buttons-red'>Cancelar</button>
                        </div>
                    </form>
                </div>
                : <div className='client-modal-children'>
                    <div className='client-modal-title fw-bold'>Crear expiración de <span>{client.username}</span></div>
                    <form className='client-modal-inputs' onSubmit={editarExpirationCliente}>
                        <div className='col-12 client-modal-inputs-row'>
                            <label className='col-3 text-center'>Expiración</label>
                            <input
                                className='col-9' type='number' placeholder='Días restantes para la expiración...'
                                {...register5('expiration', {required: true, min: 1})}
                            />
                        </div>
                        {errors5.expiration && errors5.expiration.type === 'required' && <p className='client-modal-inputs-errors'>Los días de expiración son requeridos</p>}
                        {errors5.expiration && errors5.expiration.type === 'min' && <p className='client-modal-inputs-errors'>El número de días debe ser mayor a 0</p>}
                        {updateErrors.length > 0 && <div className='login-page-errors'>
                            {updateErrors.map((error, i) => (
                                <div key={i}>{error.message}</div>
                            ))}
                            </div>
                        }
                        {updateErrors.message && <div className='login-page-errors'>
                            <div>{updateErrors.message}</div>
                        </div>
                        }
                        {loadingUpdateClient && <div className='client-modal-inputs-row'>
                                <Spinner />
                            </div>
                        }
                        <div className='client-modal-buttons'>
                            <button type='submit' onClick={editarExpirationCliente} className='client-modal-buttons-blue'>Guardar</button>
                            <button type='reset' onClick={cerrarModalEditarExpiration} className='client-modal-buttons-red'>Cancelar</button>
                        </div>
                    </form>
                </div>
                }
            </Modal>
            <Modal
                show={showEditExpirationMonth}
                setShow={cerrarModalEditarExpirationMonth}
            >
                <div className='client-modal-children'>
                    <div className='client-modal-title fw-bold'>Registrar pago de <span>{client.username}</span></div>
                    <form className='client-modal-inputs' onSubmit={editarExpirationMonthCliente}>
                        <div className='col-12 client-modal-inputs-row'>
                            <label className='col-3 text-center'>Meses a pagar</label>
                            <input
                                className='col-9' type='number' placeholder='Cantidad de meses...'
                                {...register6('months', {required: true, min: 1})}
                            />
                        </div>
                        {errors6.months && errors6.months.type === 'required' && <p className='client-modal-inputs-errors'>Los meses a asignar son requeridos</p>}
                        {errors6.months && errors6.months.type === 'min' && <p className='client-modal-inputs-errors'>El número de meses debe ser mayor a 0</p>}
                        {updateErrors.length > 0 && <div className='login-page-errors'>
                            {updateErrors.map((error, i) => (
                                <div key={i}>{error.message}</div>
                            ))}
                            </div>
                        }
                        {updateErrors.message && <div className='login-page-errors'>
                            <div>{updateErrors.message}</div>
                        </div>
                        }
                        {loadingUpdateClient && <div className='client-modal-inputs-row'>
                                <Spinner />
                            </div>
                        }
                        <div className='client-modal-buttons'>
                            <button type='submit' onClick={editarExpirationMonthCliente} className='client-modal-buttons-blue'>Guardar</button>
                            <button type='reset' onClick={cerrarModalEditarExpirationMonth} className='client-modal-buttons-red'>Cancelar</button>
                        </div>
                    </form>
                </div>
            </Modal>
        </div>
    )
}

export default Client